import Detail from './detail';
import { Create } from './Create';
import { Test } from './test';
import React from "react";
import { Outlet } from 'react-router-dom';

const Element = React.lazy(() => import('./index.component'));


export default {
    path: "team",
    element:  <Outlet />,
    children: [
        {
            index: true,
            element: <Element />
        },
        Detail,
        Create,
        Test
    ],
}